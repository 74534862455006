<template>
    <v-container fluid>
        <v-row>
            <v-col v-for="(section, index) in contents" :key="index" cols="12" sm="6">
                <v-card class="mx-auto" style="height:100%">
                    <v-card-title>{{ capitalize(section[0].section) }}</v-card-title>

                    <v-card-text>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" align="end" justify="center">
                                    <v-btn
                                        color="success"
                                        @click="saveBySection(section[0].section)"
                                    >Save</v-btn>
                                </v-col>
                                <v-col v-for="content in section" :key="content.id" cols="12">
                                    <v-text-field
                                        v-if="content.field_type === 'text'"
                                        v-model="content.value"
                                        :label="content.title"
                                        required
                                    ></v-text-field>
                                    <v-textarea
                                        v-else-if="content.field_type === 'textarea'"
                                        v-model="content.value"
                                        :label="content.title"
                                        required
                                    ></v-textarea>
                                    <wysiwyg-editor
                                        v-else
                                        v-model="content.value"
                                        :label="content.title"
                                        :key="content.id + 'w'"
                                    />
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {};
</script>

<script>
import { mapActions } from 'vuex';
import WysiwygEditor from '@/components/elements/WysiwygEditor';

export default {
    components: {
        WysiwygEditor
    },

    data: () => ({
        contents: {}
    }),

    async mounted() {
        try {
            const contents = await this.getContents();

            contents.map(content => {
                if (this.contents[content.section]) {
                    this.contents[content.section] = [
                        ...this.contents[content.section],
                        content
                    ];
                } else {
                    this.contents[content.section] = [];
                    this.contents[content.section] = [
                        ...this.contents[content.section],
                        content
                    ];
                }
            });

            this.$forceUpdate();
        } catch (err) {
            console.error(err);

            this.$toasted.error('Something went wrong!');
        }
    },

    methods: {
        ...mapActions({
            getContents: 'contents/getContents',
            updateContentsBySection: 'contents/updateContentsBySection'
        }),

        async saveBySection(section) {
            const contents = this.contents[section];

            try {
                await this.updateContentsBySection({
                    section,
                    contents
                });

                const successMessage =
                    this.capitalize(section) + ' contents have been updated!';

                this.$toasted.success(successMessage);
            } catch (err) {
                console.error(err);

                this.$toasted.error('Something went wrong!');
            }
        },

        capitalize(val) {
            if (typeof val !== 'string') {
                return '';
            }

            val = val.replace('_', ' ');

            return val.charAt(0).toUpperCase() + val.slice(1);
        }
    }
};
</script>
